<template>
  <div class="content">
    <div class="header d-flex align-items-center profile-header">
      <!-- Mask -->
      <span class="mask bg-gradient-info"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-lg-12">
            <router-link :to="{ name: 'UserDetails', params: { id: userId }}">
              <h1 class="display-3 text-white">User #{{ userId }}</h1>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">
            {{ $route.meta && $route.meta.name ? $route.meta.name : $route.name }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb></route-breadcrumb>
          </nav>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-8">
          <template>
            <card v-loading="loading">
              <div slot="header" class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0" id="user_details_heading">User Details</h3>
                </div>
                <div class="col-4 text-right">
                  <a href="javascript:" v-if="!isEditing" class="btn btn-sm btn-danger user-delete" @click="deleteUser">
                    Delete
                  </a>
                  <a href="javascript:" v-if="!isEditing" class="btn btn-sm btn-primary user-edit" @click="isEditing = true;">Edit</a>
                  <a href="javascript:" v-if="isEditing" class="btn btn-sm btn-primary user-save" @click="saveEditedUser">Save</a>
                  <a href="javascript:" v-if="isEditing" class="btn btn-sm btn-danger user-cancel" @click="isEditing = false;">Cancel</a>
                </div>
              </div>
              <user-info-form :user.sync="form" :isEditing="isEditing"></user-info-form>
            </card>
          </template>
        </div>
        <div class="col-xl-4">
          <template>
            <card id="karen">
              <div slot="header" class="row align-items-center align-item-middle">
                <div class="col-8">
                  <h3 class="mb-0">Access</h3>
                </div>
                <div class="col-4 text-right">
                  
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div>
                    <label class=" col-form-label form-control-label pr-3" style="vertical-align: super">Scheduler Access</label>
                    <span @click="schedulerAccessChanged" id="toggle_scheduler_access">
                      <base-switch v-model="has_scheduler_access"></base-switch>
                    </span>
                  </div>
                  <div>
                    <label class=" col-form-label form-control-label pr-3 text-danger" style="vertical-align: super">Deactivate User</label>
                    <span @click="userDisabledChanged" id="toggle_scheduler_access">
                      <base-switch v-model="has_user_disabled"></base-switch>
                    </span>
                  </div>

                  <hr/>
                  <label class=" col-form-label form-control-label pr-3" >Hubspot Access</label>
                  <span>
                    <badge rounded :type="hubpostAccess ? 'success' : 'danger'">{{hubpostAccess ? 'Yes' : 'No' }}</badge>
                  </span>
                  <label class=" col-form-label form-control-label pr-3" >Hubspot Email Ownership Setup</label>
                  <span>
                    <badge rounded :type="hubpostEmailAccess ? 'success' : 'danger'">{{hubpostEmailAccess ? 'Yes' : 'No' }}</badge>
                  </span>
                  <div>
                    <label class=" col-form-label form-control-label pr-3" >Hubspot Owner Id</label>
                    <span>
                      <badge rounded type="primary">{{mainOwnerId }}</badge>
                    </span>
                  </div>
                </div>
              </div>
            </card>
          </template>
        </div>
        <!-- <div class="col-xl-4">
          <template>
            <img v-if="user.thumbnail" :src="user.thumbnail" class="w-100 rounded"/>
          </template>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import {
  MessageBox,
  Loading
} from "element-ui";
import UserInfoForm from '@/views/Widgets/Users/UserInfoForm';
export default {
  components: {
    UserInfoForm,
  },
  data() {
    return {
      userId: parseInt(this.$route.params.id),
      form: {
        username: '',
        role: '',
        vc_role: '',
        first_name: '',
        last_name: '',
        ew_email: '',
        password: '',
        user_id: '',
        is_venue_specialist: true,
        vc_email: '',
        phone_number: '',
        country_code: '',
        hs_owner_id: '',
        venue_specialist_id: '',
        message_media_phone_number: '',
        sms_provider: '',
        service_role:'',
      },
      has_scheduler_access: false,
      has_user_disabled: false,
      isSchedulerAccessBtnClicked: false,
      isUserDisabledBtnClicked: false,
      isEditing: false,
      loading: true,
    };
  },
  async created() {
    await this.$store.dispatch("users/getAll");
    await this.$store.dispatch("users/getOwners");
    if (this.user.role === 'administrator' && !this.isAdmin) {
      this.$router.push({
        name: 'AllUsers',
      });
    }
    this.loading = false;
  },
  computed: {
    isAdmin() {
      return this.$store.getters["auth/isAdministrator"];
    },
    user() {
      return this.$store.getters["users/getUserById"](this.userId);
    },
    hubpostAccess() {
      let owner = this.$store.getters["users/getUserByEmail"](this.form.ew_email);
      return owner.email ? true : false;
    },
    hubpostEmailAccess() {
      let owner = this.$store.getters["users/getUserByEmail"](this.form.vc_email);
      return owner.email ? true : false;
    },
    mainOwnerId() {
      let owner = this.$store.getters["users/getUserByEmail"](this.form.ew_email);
      return owner.owner_id;
    },
    emailOwnerId() {
      let owner = this.$store.getters["users/getUserByEmail"](this.form.vc_email);
      return owner.owner_id;
    },
  },
  methods: {
    async saveEditedUser() {
      this.loading = true;
      let userDetails = await this.$store.dispatch('users/updateUser', this.form);
      this.loading = false;
      if (userDetails && userDetails.data && userDetails.data.errors) {
        let self = this;
        Object.entries(userDetails.data.errors).forEach(item => {
          self.$store.dispatch('alert/error', item.pop(), { root: true });
        });
      } else if (userDetails && userDetails.data.user) {
        this.$store.dispatch('alert/success', "User updated successfully.", { root: true });
      }
      this.isEditing = false;
    },
    schedulerAccessChanged() {
      this.isSchedulerAccessBtnClicked = true;
    },
    userDisabledChanged() {
      this.isUserDisabledBtnClicked = true;
    },
    deleteUser() {
      MessageBox.prompt(
        "Please type `DELETE` in the input box and press Delete",
        "Are you sure to delete this user?",
        {
          confirmButtonText: "Delete",
          cancelButtonText: "Cancel",
          inputPattern: /^DELETE$/,
          inputErrorMessage: "Only accept `DELETE` word",
        }
      )
        .then(({ value }) => {
          let loadingInstance = Loading.service({ fullscreen: true });
          this.$store.dispatch("users/deleteUser", {
            user_id: this.userId,
            is_venue_specialist: this.form.is_venue_specialist ? 1 : 0,
            venue_specialist_id:  this.form.venue_specialist_id,
          }).then(() => {
            loadingInstance.close();
            this.$router.push({ name: "AllUsers" });
          });
        })
        .catch((err) => {
          //console.log(err);
        });
    },
  },
  watch: {
    user() {
      let name = this.user.is_venue_specialist ? this.user.venue_specialist_data.name : this.user.display_name;
      let temp = {};
      temp.username = this.user.username;
      temp.role = this.user.role;
      temp.vc_role = this.user.vc_role;
      temp.first_name = name.split(' ')[0];
      temp.last_name = name.split(' ')[1];
      temp.ew_email = this.user.email;
      temp.user_id = this.user.user_id;
      temp.is_venue_specialist = this.user.is_venue_specialist;
      temp.vc_email = temp.is_venue_specialist ? this.user.venue_specialist_data.email : '';
      temp.phone_number = temp.is_venue_specialist ? this.user.venue_specialist_data.phone_number : '';
      temp.country_code = temp.is_venue_specialist ? this.user.venue_specialist_data.country : '';
      temp.hs_owner_id = temp.is_venue_specialist ? this.user.venue_specialist_data.hubspot_owner_id : '';
      temp.venue_specialist_id = temp.is_venue_specialist ? this.user.venue_specialist_data.venue_specialist_id : '';
      temp.message_media_phone_number = temp.is_venue_specialist ? this.user.venue_specialist_data.message_media_phone_number : '';
      temp.service_role = temp.is_venue_specialist ? this.user.venue_specialist_data.service_role : '';
      temp.sms_provider = temp.is_venue_specialist ? this.user.venue_specialist_data.sms_provider : '';
      this.form = temp;

      this.has_scheduler_access = this.user.has_scheduler_access;
      this.has_user_disabled = parseInt(this.user.user_disabled) ? true : false;
    },
    async has_scheduler_access() {
      if (this.isSchedulerAccessBtnClicked) {
        this.isSchedulerAccessBtnClicked = false;
        this.loading = true;
        await this.$store.dispatch('users/updateSchedulerAccess', {
          user_id: this.userId,
          has_scheduler_access: this.has_scheduler_access,
        });
        await this.$store.dispatch("users/getAll");
        this.loading = false;
      }
    },
    async has_user_disabled() {
      if (this.isUserDisabledBtnClicked) {
        this.isUserDisabledBtnClicked = false;
        this.loading = true;
        await this.$store.dispatch('users/updateDisableUser', {
          user_id: this.userId,
          user_disabled: this.has_user_disabled,
        });
        await this.$store.dispatch("users/getAll");
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.btn {
  color: white;
}
.btn:hover {
  color: white;
}
</style>